// config.js

const bizIcon = 'https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/c6c35376-d542-4a56-a87f-f1f6e0d563d2ad93.png?alt=media&token=66764e06-0917-49be-8b52-b567c7738964';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/91cac3b0-0043-49be-bc50-b584025982a9ecc4.jpg?alt=media&token=1efba88a-eaf1-4649-9cf1-7f1484d37b44";
const imageTwo = "https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/15a6b363-f286-4d31-b455-c5eccb42d906e98f.jpg?alt=media&token=0023ac86-22bb-4a3f-a274-d0884858d0b5";
const imageThree = "https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/10cbfb77-6824-4e33-b390-d63a2a868fbcb77a.jpeg?alt=media&token=c40a6e7d-d971-4dc5-8485-fb2b3ad7e568";
const imageFour = "https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/c2f3abe9-6047-4752-9434-d782c42f1a7a80ae.jpg?alt=media&token=152113ec-a07c-4530-8380-8aa3543dcb98";
const imageFive = "https://www.ownersemail.com/firebasestorage.googleapis.com/v0/b/owners-production-361612.appspot.com/o/airtable-assets/94171cde-337b-456e-91d1-4866d7b8c37f0d2b.jpg?alt=media&token=bc3c9795-c577-440f-b926-66eddd4691ba";

const redirectLink = "https://zaldivarcleaningenterprise.com/";

export const siteConfig = {
  title: "Zaldivar Cleaning Enterprise",
  metaDescription: "Zaldivar Cleaning Enterprise",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-600"
  },
  logo: {
    src: bizIcon,
    alt: "Zaldivar Cleaning Enterprise Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Zaldivar Cleaning Enterprise",
    headline: "Transforming Spaces with Pristine Cleanliness in Baltimore, MD",
    address: "Baltimore, MD",
    description: `
        Zaldivar Cleaning Enterprise is your trusted partner for top-tier cleaning services in Baltimore, MD. From Airbnb and residential cleaning to commercial, office, and post-construction cleanup, we pride ourselves on delivering spotless results tailored to your needs. Our dedicated team ensures that every space we touch is refreshed, revitalized, and ready for use. Experience the difference with Zaldivar Cleaning Enterprise—where cleanliness meets excellence.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Transforming Spaces with Pristine Cleanliness in Baltimore, MD",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Family-Owned and Locally Operated: Proudly serving the Baltimore community with personalized cleaning services backed by a commitment to excellence.",
    },
    {
        description: 
          "Transformative Cleaning Expertise: From Airbnb to commercial and post-construction cleaning, our services are designed to leave every space spotless and revitalized.",
    },
    {
        description: 
          "Trusted Professionals: With a focus on reliability, attention to detail, and customer satisfaction, we ensure exceptional results for homes and businesses alike.",
    },
  ],
  services: {
    sectionTitle: "Our Cleaning Services",
    description: 
      "Zaldivar Cleaning Enterprise offers comprehensive cleaning solutions tailored to the needs of residential, commercial, office, and construction spaces in Baltimore, MD. From meticulous Airbnb cleaning to transformative post-construction cleanups, we ensure every space is spotless and inviting.",
    callouts: [
      {
        name: 'Office Cleaning',
        description: 
          "Our expert team provides thorough office cleaning services to maintain a clean, organized, and professional environment for your workspace.",
        imageSrc: imageTwo,
        imageAlt: 'Team performing professional office cleaning.',
      },
      {
        name: 'Residential Cleaning',
        description: 
          "Experience pristine homes with our residential cleaning services designed to refresh every corner of your living space.",
        imageSrc: imageThree,
        imageAlt: 'Expert cleaning service revitalizing a residential living room.',
      },
      {
        name: 'Commercial Cleaning',
        description: 
          "Comprehensive commercial cleaning solutions that cater to businesses of all sizes, ensuring a clean and welcoming space for clients and employees.",
        imageSrc: imageFour,
        imageAlt: 'Commercial property being cleaned by professionals.',
      },
    ],
    otherServices: [
      "Airbnb Cleaning",
      "Pre and Post-Construction Cleaning",
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "Welcome to Zaldivar Cleaning Enterprise, your trusted partner for all cleaning needs in Baltimore, MD. We specialize in a wide range of services, including Airbnb, residential, commercial, office, and pre and post-construction cleaning. Our team of skilled professionals is dedicated to delivering exceptional results with attention to detail and superior customer service. Whether it's maintaining a spotless home, refreshing your office, or tackling a post-construction cleanup, we ensure a clean, healthy, and stress-free environment tailored to your needs.",
    image: {
      src: imageFive,
      alt: "Zaldivar Cleaning Enterprise team providing professional cleaning services in Baltimore, MD.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Baltimore, MD",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.795799179128!2d-76.61218968466945!3d39.29038407953337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8037e84455b7f%3A0xabcdef123456789!2sBaltimore%2C%20MD%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Baltimore,%20MD,%20USA',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Baltimore,+MD,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Baltimore,+MD,+USA/@39.290384,-76.612189,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Baltimore,+MD,+USA/@39.290384,-76.612189,15z',
      },
    ],  
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: 
      "Transform your spaces with Zaldivar Cleaning Enterprise. Whether you need expert cleaning for your Airbnb, home, office, or construction site, our dedicated team is ready to assist. Contact us today for a free quote and experience pristine cleaning services that make a difference.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (443) 335-4790",
    ],
    logo: bizIcon,
    emailRecipient: "info@zaldivarcleaningenterprise.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


